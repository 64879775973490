import {
  FaHtml5,
  FaCss3,
  FaNodeJs,
  FaReact,
  FaSass,
  FaFigma,
  FaPhoneAlt,
  FaUser,
  FaMapMarkerAlt,
  FaPaperPlane,
  FaDribbble,
  FaGithub,
  FaInstagram,
  FaLinkedin,
  FaVuejs,
} from "react-icons/fa";
import { DiJavascript1 } from "react-icons/di";
import { SiTypescript, SiFlutter, SiThreedotjs } from "react-icons/si";
import { BsMedium } from "react-icons/bs";
import portfolio from "./assets/portfolio.jpg";
import work1 from "./assets/work1.png";
import work01 from "./assets/work-01.png";
import work02 from "./assets/work-02.png";
import work03 from "./assets/work-03.png";
import work04 from "./assets/work-04.png";
import work05 from "./assets/work-05.png";
import work06 from "./assets/work-06.png";
import work2 from "./assets/work2.png";
import work3 from "./assets/work3.png";
import work4 from "./assets/work4.png";
import work5 from "./assets/work5.png";
import work6 from "./assets/work6.png";

export const navLinks = ["home", "about", "skills", "portfolio", "contact"];

export const socialIcons = [
  <FaGithub />,
  <FaInstagram />,
  <FaLinkedin />,
  <BsMedium />,
];

export const bios = [
  {
    id: 1,
    icon: <FaUser />,
    key: "Name",
    value: "Jin",
  },
  {
    id: 2,
    icon: <FaPhoneAlt />,
    key: "Phone",
    value: "+82-10-2197-8799",
  },
  {
    id: 3,
    icon: <FaPaperPlane />,
    key: "Email",
    value: "mail@jinhyuk.kim",
  },
];

export const icons = [
  <DiJavascript1 />,
  <FaNodeJs />,
  <SiTypescript />,
  <SiThreedotjs />,
  <FaVuejs />,
  <FaReact />,
  <SiFlutter />,
  <FaFigma />,
];

export const experiences = [
  {
    id: 1,
    year: "2023",
    position: "Leader developer",
    company: "Vitamate",
  },
  {
    di: 2,
    year: "2021",
    position: "Junior Web Developer",
    company: "metalab.co.kr",
  },
  {
    id: 3,
    year: "2014",
    position: "Junior Web Dveloper",
    company: "Lotte department store",
  },
];
export const finishes = [
  {
    id: 1,
    number: "3+",
    itemName: "Years Of Experience",
  },
  {
    id: 2,
    number: "50+",
    itemName: "Satisfied Customers",
  },
  {
    id: 3,
    number: "79+",
    itemName: "Designed Items",
  },
  {
    id: 4,
    number: "17+",
    itemName: "Clients Served",
  },
];
export const workImages = [
  {
    id: 1,
    img: work01,
    name: "AI Image Generator with OpenAI",
    category: "web",
    viewLink: "https://dalle.jinhyuk.kim",
  },
  {
    id: 2,
    img: work02,
    name: "AI Chatting Program with OpenAI",
    category: "web",
    viewLink: "https://gpt.jinhyuk.kim",
  },
  {
    id: 3,
    img: work03,
    name: "AI Image Generator with VQ-GAN",
    category: "web",
    viewLink: "https://artmon.ai",
  },
  {
    id: 4,
    img: work04,
    name: "METAVERSE WEB Clone coding",
    category: "web",
    viewLink: "#",
  },
  {
    id: 5,
    img: work05,
    name: "custom Tello with ChatGpt Summary",
    category: "web",
    viewLink: "https://trello.jinhyuk.kim",
  },
  {
    id: 6,
    img: work06,
    name: "AI art generator",
    category: "app",
    viewLink: "https://play.google.com/store/apps/details?id=ai.artmon.aatom",
  },
];

export const workNavs = ["All", "Web", "App", "Design"];

export const contacts = [
  {
    id: 1,
    icon: <FaMapMarkerAlt />,
    infoText: "Gangnamdaero, Seoul, South Korea",
  },
  {
    id: 2,
    icon: <FaPaperPlane />,
    infoText: "mail@jinhyuk.kim",
  },
  {
    id: 3,
    icon: <FaPhoneAlt />,
    infoText: "+82-10-2197-8799",
  },
];
