import React from "react";
import profile from "../../../assets/profile-avatar.jpg";
import "./Home.scss";
import { motion } from "framer-motion";
import { TypeAnimation } from "react-type-animation";

const Home = () => {
  const moveVariants = {
    animation: {
      y: [0, -15],
      transition: {
        yoyo: Infinity,
        duration: 2,
        delay: 1,
      },
    },
  };

  return (
    <motion.div
      className="container"
      id="home"
      initial={{ y: -15, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{
        duration: 2,
        delay: 0.5,
      }}
    >
      <div className="profile">
        <img src={profile} alt="portfolio" />
      </div>
      <div className="typingAnimation">
        <TypeAnimation
          className=""
          sequence={[
            "Hi", // Types 'One'
            1000, // Waits 1s
            "I am", // Deletes 'One' and types 'Two'
            2000, // Waits 2s
            "Jin", // Types 'Three' without deleting 'Two'
            5000, // Waits 2s
            () => {
              console.log("Done typing!"); // Place optional callbacks anywhere in the array
            },
          ]}
          wrapper="div"
          cursor={true}
          repeat={Infinity}
          style={{ fontSize: "2em" }}
        />
      </div>
      <div className="profile_text">
        {/* <h3 className='name'>Hi, I'm <span>Abee Zarar</span> </h3> */}
        <span className="job">Web Developer Based on Korea</span>
        <span className="text">
          Passionate
          <br /> to craft innovative <br /> web products.
        </span>
        <motion.a
          href="#contact"
          whileHover={{ scale: 1.1 }}
          variants={moveVariants}
          animate="animation"
        >
          connect with me
        </motion.a>
        <div className="web">Web Developer</div>
        <div className="ui">UI/UX Designer</div>
        <div className="freelance">Freelancer</div>
      </div>
    </motion.div>
  );
};

export default Home;
